<template>
    <!-- 一个手机框案例展示组件 -->
    <div
        class="vy-phone"
        @click="goDetail(element)"
        v-if="element"
        :style="{ cursor: element.ueditorData || element.project_url ? 'pointer' : 'default' }"
    >
        <!-- 手机框图片 -->
        <img class="phone" src="http://iv.okvu.cn/vugw/img/sj.png" />
        <div>
            <img v-lazy="element.little_image" :style="{ 'object-fit': objectFit }" />
            <!-- 遮罩 -->
            <div class="mask" v-if="element.qrcode || element.logo">
                <img class="mask_img" v-if="element.qrcode" v-lazy="element.qrcode" />
                <img class="mask_img" v-else v-lazy="element.logo" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        element: {
            type: Object,
            default: () => {},
        },
        objectFit: {
            type: String,
            default: "cover",
        },
    },
    data() {
        return {};
    },
    methods: {
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            // 点击后的回调
            this.$emit("callBack");
        },
    },
};
</script>

<style lang="less" scoped>
.vy-phone {
    width: 240px;
    height: 460px;
    position: relative;
    &:hover {
        .mask {
            left: 0;
            opacity: 1;
        }
    }
    .phone {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 6;
    }
    & > div {
        background-color: white;
        width: 88%;
        height: 95%;
        overflow: hidden;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        & > img {
            width: 100%;
            height: 100%;
        }
        .mask {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: -100%;
            transition: 0.5s;
            opacity: 0;
            z-index: 1;
            background-color: rgba(34, 31, 31, 0.5);
            .mask_img {
                width: 150px;
                height: 150px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: contain;
            }
        }
    }
}
</style>
